import { Button } from '@nextui-org/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

const AllTds = () => {


    const [tds, setTds] = useState([]);
    const [uiChange, setUiChange] = useState(false);
    const getAllTds = async () => {

        try {
            const response = await axios.get("/tds-gst/all-tds");
            if (response.data.success) {
                setTds(response.data.tds);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    const deleteTds = async (id) => {
        const result = window.confirm("Sure? Want to delete ?");

        if (!result) {
            return
        }

        try {
            const response = await axios.delete(`/tds-gst/tds/${id}`);
            if (response.data.success) {
                toast.success(response.data.message);
                setUiChange(!uiChange);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }



    useEffect(() => {
        getAllTds();
    }, [uiChange])


    return (
        <>
            <h1 className='text-center mt-2 underline'>TDS</h1>
            <div className="border rounded-lg overflow-hidden mt-5 shadow mx-6">
                <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                        <tr>
                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Date
                            </th>
                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Amount
                            </th>
                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Mode of Payment
                            </th>
                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Reason
                            </th>

                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Credited
                            </th>
                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Debited
                            </th>
                            <th
                                scope="col"
                                className="font-bold px-6 py-3 text-start text-s text-gray-500 uppercase"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {tds?.map((e, index) => {
                            return (
                                <>
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800">
                                            {new Date(e?.date).toLocaleDateString("en-GB")}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                            {e?.amount}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                            {e?.modeOfPayment}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                            {e?.reason}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                            {e?.credited}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                                            {e?.debited}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">

                                            <i onClick={() => deleteTds(e?._id)} class="fa-solid fa-trash text-red-600 hover:cursor-pointer hover:scale-150"></i>

                                        </td>

                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AllTds;