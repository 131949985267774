import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './pages/Layout';
import Login from "./pages/Login";
import Signup from './pages/Signup';
import Home from './pages/Home';
import ResetPassword from './pages/ResetPassword'
import { NextUIProvider } from '@nextui-org/react';
import axios from 'axios';
import { useSelector } from 'react-redux'
import UserVerification from './pages/UserVerification';
import { ToastContainer } from 'react-toastify';
import "react-toastify/ReactToastify.css";
import CreateUser from './pages/user/CreateUser';
import AddExpense from './pages/expenses/AddExpense';
import AllExpense from "./pages/expenses/AllExpenses"
import AllUsers from './pages/user/AllUsers';
import UpdateUser from './pages/user/UpdateUser';
import ProtectedRoute from './pages/ProtectedRoute';
import ChangePassword from './pages/user/ChangePassword';
import AllRecords from './pages/AllRecords';
import AddTrip from './pages/trip/AddTrip';
import UpdateTrip from './pages/UpdateTrip';
import Analytics from './pages/trip/Analytics';
import { useEffect } from 'react';
import AllTds from './pages/tds/AllTds';
import AddTds from './pages/tds/AddTds';
import AllGst from './pages/gst/AllGst';
import AddGst from './pages/gst/AddGst';


function App() {

  const { token } = useSelector(state => state.user);


  axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.withCredentials = true;


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Login />
        },
        {
          path: "signup",
          element: <Signup />
        },
        {
          path: 'resetPassword/:email',
          element: <ResetPassword />
        },
        {
          path: 'userVerification',
          element: <UserVerification />
        },
        {
          path: 'home',
          element: <ProtectedRoute><Home /></ProtectedRoute>
        },
        {
          path: "addExpenses",
          element: <ProtectedRoute><AddExpense /></ProtectedRoute>
        },
        {
          path: "allExpenses",
          element: <ProtectedRoute><AllExpense /></ProtectedRoute>
        },
        {
          path: 'createUser',
          element: <ProtectedRoute><CreateUser /></ProtectedRoute>
        },
        {
          path: 'allUsers',
          element: <ProtectedRoute><AllUsers /></ProtectedRoute>
        },
        {
          path: 'updateUser/:id',
          element: <ProtectedRoute><UpdateUser /></ProtectedRoute>
        },
        {
          path: 'changePassword',
          element: <ProtectedRoute><ChangePassword /></ProtectedRoute>
        },
        {
          path: "all-records",
          element: <ProtectedRoute><AllRecords /></ProtectedRoute>
        },
        //trip
        {
          path: "addTripManually",
          element: <ProtectedRoute><AddTrip /></ProtectedRoute>
        },
        {
          path: "updateTrip/:id",
          element: <ProtectedRoute><UpdateTrip /></ProtectedRoute>
        },
        {
          path: "analytics",
          element: <ProtectedRoute><Analytics /></ProtectedRoute>
        },
        {
          path: "all-tds",
          element: <ProtectedRoute><AllTds /></ProtectedRoute>
        },
        {
          path: "add-tds",
          element: <ProtectedRoute><AddTds /></ProtectedRoute>
        },
        {
          path: "all-gst",
          element: <ProtectedRoute><AllGst /></ProtectedRoute>
        },
        {
          path: "add-gst",
          element: <ProtectedRoute><AddGst /></ProtectedRoute>
        }
      ]
    },

  ])
  return (
    <>
      <NextUIProvider>
        <RouterProvider router={router} />
        <ToastContainer autoClose={3000} />
      </NextUIProvider>
    </>
  );
}

export default App;
