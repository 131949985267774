import { Button, Input, Select, SelectItem, Textarea } from '@nextui-org/react'
import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddGst = () => {
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        const data = Object.fromEntries(form.entries());
        console.log(data);

        try {
            const response = await axios.post("/tds-gst/add-gst", data);
            if (response.data.success) {
                toast.success(response.data.message);
                navigate("/all-gst");
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    return (
        <>
            <h1 className='text-center italic underline font-bold mt-3'>Add GST</h1>
            <div className="flex justify-center h-[100vh] items-center">
                <form onSubmit={handleSubmit} className='space-y-3 border-1 border-gray-400 p-5 rounded-2xl w-[60vw] sm:w-[60vw] md:w-[40vw] lg:w-[30vw]'>
                    <Input type="date" label={"Date"} name='date' />
                    <Input type='number' label="Amount" name='amount' placeholder="Enter amount" />
                    <Select name='modeOfPayment' label="Mode of payment">
                        <SelectItem key="CASH">
                            CASH
                        </SelectItem>
                        <SelectItem key="UPI">
                            UPI
                        </SelectItem>
                        <SelectItem key="NEFT">
                            NEFT
                        </SelectItem>
                    </Select>
                    <Textarea label="Reason" name='reason' placeholder="Write a reason of your expenses" />
                    <Input type='number' name='debited' label="Debited" />
                    <Input type='number' name='credited' label="Credited" />
                    <div className='text-center'>
                        <Button type='submit' color='primary'>Submit</Button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddGst